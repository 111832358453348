import { DocumentSnapshot, Timestamp } from "../firestoreImports"
import { z } from "zod"
import { User } from "./user"
import { ReservationStatus } from "./reservation"

const fleetReservationSchema = z.object({
    userId: z.string(),
    startTime: z.custom<Timestamp>(),
    endTime: z.custom<Timestamp>(),
    canceled: z.boolean(),
    dateCanceled: z.custom<Timestamp>().optional(),
    fleetCarId: z.string(),
    timestampCreated: z.custom<Timestamp>(),
    note: z.string().optional(),
    odometerInitial: z.number().optional(),
    odometerFinal: z.number().optional()
})

interface IFleetReservation {
    id: string
    userId: string
    startTime: Date
    endTime: Date
    canceled: boolean
    dateCanceled?: Date
    fleetCarId: string
    timestampCreated?: Date
    note?: string
    odometerInitial?: number
    odometerFinal?: number
}
export interface FleetReservation extends IFleetReservation {}
export class FleetReservation {
    // Helper property
    user?: User

    constructor(params: IFleetReservation) {
        Object.assign(this, params)
    }

    static fromData(doc: DocumentSnapshot): FleetReservation | null {
        const data = doc.data()
        if (!data) {
            console.warn(`Cannot create fleet reservation ${doc.id} from snapshot`)
            return null
        }

        try {
            const parsed = fleetReservationSchema.parse(data)
            return new FleetReservation({
                id: doc.id,
                userId: parsed.userId,
                startTime: parsed.startTime.toDate(),
                endTime: parsed.endTime.toDate(),
                canceled: parsed.canceled,
                dateCanceled: parsed.dateCanceled?.toDate(),
                fleetCarId: parsed.fleetCarId,
                timestampCreated: parsed.timestampCreated.toDate(),
                note: parsed.note,
                odometerInitial: parsed.odometerInitial,
                odometerFinal: parsed.odometerFinal
            })
        } catch (error) {
            console.log(`Cannot create fleet reservation ${doc.id} from snapshot: ${error}`)
            return null
        }
    }

    asData(): { [k: string]: string | number | Date | boolean | undefined } {
        return {
            userId: this.userId,
            startTime: this.startTime,
            endTime: this.endTime,
            canceled: this.canceled ?? false,
            dateCanceled: this.dateCanceled,
            fleetCarId: this.fleetCarId,
            timestampCreated: this.timestampCreated ?? new Date(),
            note: this.note,
            odometerInitial: this.odometerInitial,
            odometerFinal: this.odometerFinal
        }
    }

    status = () => {
        if (this.canceled) {
            return ReservationStatus.Canceled
        }
        return ReservationStatus.Confirmed
    }
}
