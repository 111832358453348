import React from "react"
import { Fade } from "react-awesome-reveal"
import moment from "moment"
import "moment/locale/cs"
import { DayPicker, DayModifiers } from "react-day-picker"
import { enUS, cs } from "date-fns/locale"
import "react-day-picker/dist/style.css"
import "styles/day-picker.scss"
import { FormattedMessage as M, useIntl, IntlShape, PrimitiveType } from "react-intl"
import axios from "axios"
import Markdown from "react-markdown"
import rehypeStringify from "rehype-stringify"
import remarkParse from "remark-parse"
import remarkRehype from "remark-rehype"
import { unified } from "unified"

import Firebase, { withFirebase, User, ApiError, ApiErrorCode, ReservationResultState, FleetReservation, FleetCar } from "../Firebase"
import { UserStateCheck } from "../../shared-components/Session"
import WebAPI from "../Firebase/webAPI"
import Utilities from "../Firebase/Models/utilities"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Swal from "sweetalert2/dist/sweetalert2.js"
import withReactContent from "sweetalert2-react-content"
import { StaticDatePicker } from "components/Admin/visitorsBooking"
import muiTheme from "otherFiles/muiTheme"
const MySwal = withReactContent(Swal)

enum SelectionState {
    None = 0,
    Past,
    ReservationOpen,
    ReservationNotOpen,
    Reserved
}

class ReservationData {
    topElement: JSX.Element
    firstButtonText: string
    firstButtonAction: () => void
    secondButtonText: string
    secondButtonAction: () => void

    constructor() {
        this.topElement = <div></div>
        this.firstButtonText = ""
        this.firstButtonAction = () => {}
        this.secondButtonText = ""
        this.secondButtonAction = () => {}
    }
}

interface IReserveFleetCarBaseState {
    reservations?: FleetReservation[]
    reservationGroups?: { [timestamp: number]: FleetReservation[] }
    actionInProgress: boolean
    selectedDay: Date
    selectionState: SelectionState
    selectedReservations: FleetReservation[]
    fleetCars: FleetCar[]
}
interface IReserveFleetCarBaseProps {
    user: User
    firebase: Firebase
    intl: IntlShape
}
class ReserveFleetCarBase extends React.Component<IReserveFleetCarBaseProps, IReserveFleetCarBaseState> {
    //Enabled calendar period
    enabledStart = new Date()
    enabledEnd = moment().add(5, "days").endOf("isoWeek").add(4, "week").toDate()
    source = axios.CancelToken.source()
    get timezone() {
        return this.props.user.activeLocation?.timezone ?? "Europe/Prague"
    }
    endDate = moment()

    constructor(props: IReserveFleetCarBaseProps) {
        super(props)

        let selectedDay = new Date()

        this.state = {
            reservations: undefined,
            reservationGroups: undefined,
            actionInProgress: false,
            selectedDay: selectedDay,
            selectionState: SelectionState.None,
            selectedReservations: [],
            fleetCars: []
        }

        moment.locale(this.props.intl.locale)
    }

    componentDidMount() {
        this.updateData()
    }

    async updateData() {
        if (this.props.user.fleetIds.length === 0) {
            console.log("No fleets to display")
            return
        }
        this.setState({ reservations: undefined })
        await Promise.all([this.fetchReservations(), this.fetchFleetCars()])
        this.handleDayClick(this.state.selectedDay)
    }

    setAsyncState = (newState: any) => {
        return new Promise<void>(resolve => this.setState(newState, resolve))
    }

    fetchFleetCars = async () => {
        try {
            const fleetCars = await this.props.firebase.fetchAllFleetCars(this.props.user.fleetIds[0])
            this.setState({ fleetCars })
        } catch (error) {
            console.warn("Error getting fleet cars: ", error)
        }
    }

    fetchReservations = async () => {
        const startDate = moment.tz(this.timezone).add(-1, "months").startOf("day").toDate()
        const endDate = moment.tz(this.timezone).add(1, "months").endOf("day")
        const allReservations = await this.props.firebase.fetchAllFleetReservations(
            this.props.user.fleetIds[0],
            startDate,
            endDate.toDate(),
            this.props.user.uid
        )
        const reservations = allReservations.filter(r => !r.canceled)

        const reservationGroups: { [timestamp: number]: FleetReservation[] } = {}
        let date = moment.tz(startDate, this.timezone).startOf("day")
        while (date <= endDate) {
            const dateStart = moment(date).startOf("day")
            const dateEnd = moment(date).endOf("day")
            const dayReservations = reservations.filter(r => moment(r.startTime) < dateEnd && moment(r.endTime) > dateStart)
            if (dayReservations?.length > 0) {
                reservationGroups[date.unix()] = dayReservations
            }
            date.add(1, "day")
        }

        return this.setAsyncState({ reservations, reservationGroups })
    }

    fm = (messageId: string, values: Record<string, PrimitiveType> | undefined = undefined) => {
        return this.props.intl.formatMessage({ id: messageId }, values)
    }

    getAvailableCarsForSelect = async (startTime: Date, endTime: Date) => {
        const cars = await this.props.firebase.fetchAvailableCars(this.props.user.fleetIds[0], startTime, endTime)
        if (cars.length === 0) {
            throw new ApiError("There are no available cars", ApiErrorCode.NoCars)
        }
        return cars
    }

    noteToHtml = async (note: string) => {
        const noteHtml = await unified()
            .use(remarkParse) // Parse markdown content to a syntax tree
            .use(remarkRehype) // Turn markdown syntax tree to HTML syntax tree, ignoring embedded HTML
            .use(rehypeStringify) // Serialize HTML syntax tree
            .process(note)
        return String(noteHtml)
    }

    addReservation = async () => {
        this.setState({ actionInProgress: true })

        try {
            let result: { startTime: moment.Moment; endTime: moment.Moment } | null
            result = await this.halfdayReservationFlow()

            if (result === null) {
                result = await this.minutesReservationFlow()
            }

            const availableCars = await this.getAvailableCarsForSelect(result.startTime.toDate(), result.endTime.toDate())
            const carIds = availableCars.reduce((a, car) => ({ ...a, [car.id!]: `${car.title} (${car.plateNum})` }), {})
            let selectedCar = availableCars[0]
            const carResult = await MySwal.fire({
                title: this.fm("reserve.select_car"),
                input: "select",
                inputOptions: carIds,
                html: (
                    <div id="swal-note" className="text-start">
                        <Markdown>{selectedCar.note}</Markdown>
                    </div>
                ),
                customClass: { input: "select_arrow y-style" },
                confirmButtonText: this.fm("general.next") + " &rarr;",
                showCancelButton: true,
                didOpen: () => {
                    const input = Swal.getInput()
                    const noteDiv = document.getElementById("swal-note")
                    if (input && noteDiv) {
                        input.onchange = async () => {
                            const selectedCar = availableCars.find(c => c.id === input.value)
                            if (selectedCar && selectedCar.note) {
                                noteDiv.innerHTML = "<div>" + (await this.noteToHtml(selectedCar.note)) + "</div>"
                            } else {
                                noteDiv.innerHTML = ""
                            }
                        }
                    }
                }
            })
            if (!carResult?.value) {
                throw new Error("closed")
            }
            const carId = carResult.value

            const note = await this.noteSwal()
            this.loadingSwal()

            const reservationResult = await WebAPI.reserveFleetCar(
                this.props.user.uid,
                this.props.user.fleetIds[0],
                result.startTime.toDate(),
                result.endTime.toDate(),
                carId,
                note
            )
            this.setState({ actionInProgress: false })

            let title = "There are no more available cars"
            let icon: "success" | "info" = "info"
            if (reservationResult.state === ReservationResultState.Confirmed) {
                title = this.fm("reserve.reservation_submitted")
                icon = "success"
            }
            MySwal.fire({
                title: title,
                html: "",
                icon: icon,
                showConfirmButton: false,
                timer: 3500,
                timerProgressBar: true
            })

            await this.fetchReservations()
            await this.handleDayClick(this.state.selectedDay)
        } catch (error: any) {
            this.setState({ actionInProgress: false })
            if (error.message === "closed") {
                return
            }
            console.log("Error: ", error)

            if (error instanceof ApiError && error.code === ApiErrorCode.NoCars) {
                return MySwal.fire({
                    title: "No cars available",
                    text: `There are no available fleet cars at this time period.`,
                    icon: "info"
                })
            }

            if (error instanceof ApiError && error.code === ApiErrorCode.ConflictReservation) {
                return MySwal.fire({
                    title: "Conflicting reservation",
                    text: `This car is already reserved.`,
                    icon: "warning"
                })
            }
            return MySwal.fire({
                title: "Error",
                text: `There was an error while submitting the reservation. ${error?.message || "Please try again later."}`,
                icon: "error"
            })
        }
    }

    cancelReservation = async (reservation: FleetReservation) => {
        MySwal.showLoading()
        this.setState({ actionInProgress: true })
        try {
            await WebAPI.cancelFleetReservation(this.props.user.fleetIds[0], reservation.id)

            this.setState({ actionInProgress: false })
            MySwal.fire({
                title: this.fm("reserve.cancel_success"),
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true
            })
            await this.fetchReservations()
            this.handleDayClick(this.state.selectedDay)
        } catch (error) {
            this.setState({ actionInProgress: false })
            MySwal.fire({
                title: "Error",
                text: "There was an error while canceling the reservation. Please try again later.",
                icon: "error"
            })
            console.warn("Error canceling reservation: ", error)
        }
    }

    recordOdometer = async (reservation: FleetReservation, fleetCar?: FleetCar) => {
        try {
            const initialState = await this.odometerSwal(
                this.fm("fleet_reservation.odometer_initial"),
                this.fm("fleet_reservation.odometer_initial_text"),
                reservation.odometerInitial ?? fleetCar?.odometer
            )
            const finalState = await this.odometerSwal(
                this.fm("fleet_reservation.odometer_final"),
                this.fm("fleet_reservation.odometer_final_text"),
                reservation.odometerFinal
            )
            const note = await this.noteSwal(reservation.note)
            this.loadingSwal()

            await this.props.firebase.updateFleetReservation(this.props.user.fleetIds[0], reservation.id, {
                odometerInitial: initialState,
                odometerFinal: finalState,
                note: note
            })

            if (fleetCar) {
                await this.props.firebase.updateFleetCar(this.props.user.fleetIds[0], fleetCar.id, {
                    odometer: finalState
                })
            }

            await this.fetchFleetCars()
            await this.fetchReservations()
            this.handleDayClick(this.state.selectedDay)

            MySwal.fire({
                title: this.fm("general.save_success"),
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
                timerProgressBar: true
            })
        } catch (error: unknown) {
            if (error instanceof Error) {
                if (error.message === "closed") {
                    return
                }
            }
            console.log("Unknown error occurred: ", error)
            MySwal.fire({
                title: "Error",
                text: "There was an error. Please try again later.",
                icon: "error"
            })
        }
    }

    renderDay = (date: Date, modifiers: DayModifiers) => {
        return <span>{date.getDate()}</span>
    }

    handleDayClick = (day: Date): void => {
        const dateStart = moment(day).startOf("day")
        const dateEnd = moment(day).endOf("day")
        const selectedReservations = this.state.reservations?.filter(r => moment(r.startTime) < dateEnd && moment(r.endTime) > dateStart) ?? []

        let selectionState
        if (selectedReservations.some(r => r.canceled === false)) {
            selectionState = SelectionState.Reserved
        } else {
            const today = moment.tz(this.timezone).startOf("day").toDate()

            if (day < today) {
                selectionState = SelectionState.Past
            } else if (day <= this.enabledEnd) {
                selectionState = SelectionState.ReservationOpen
            } else {
                selectionState = SelectionState.ReservationNotOpen
            }
        }

        this.setState({
            selectedDay: day,
            selectionState: selectionState,
            selectedReservations: selectedReservations
        })
    }

    onSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.name === "fleet") {
            this.setState({ reservations: undefined })
            await this.props.firebase.setUserActiveFleet(this.props.user, event.target.value)
            this.updateData()
        }
    }

    render() {
        if (this.state.reservations === undefined || this.state.reservationGroups === undefined) {
            return <div className="loader"></div>
        }

        const modifiers = {
            confirmed: Object.keys(this.state.reservationGroups).map(value => moment.unix(Number(value)).toDate()),
            outsideRange: { before: this.enabledStart, after: this.enabledEnd }
        }
        const modifiersClassNames = {
            pending: "rdp-day--pending",
            confirmed: "rdp-day--confirmed",
            declined: "rdp-day--declined",
            outsideRange: "rdp-day--outside-range"
        }

        let reservationsData: ReservationData[] = []
        switch (this.state.selectionState) {
            case SelectionState.ReservationOpen: {
                const reservationData = new ReservationData()
                reservationData.firstButtonAction = () => this.addReservation()
                reservationData.topElement = <div></div>
                reservationData.firstButtonText = this.fm("reserve.reserve")
                reservationsData = [reservationData]
                break
            }

            case SelectionState.Reserved: {
                for (const reservation of this.state.selectedReservations) {
                    const reservationData = new ReservationData()
                    const date = Utilities.reservationTimeToText(reservation, this.timezone, this.props.intl.locale)
                    const fleetCar = this.state.fleetCars.find(car => car.id === reservation.fleetCarId)
                    reservationData.topElement = (
                        <div className="text-start">
                            <div>
                                <strong>{fleetCar?.title ?? "Unknown car"}</strong>
                            </div>
                            <div>
                                <Markdown>{fleetCar?.note}</Markdown>
                            </div>
                            <div>
                                <M id="fleet_reservation.odometer" />: {fleetCar?.odometer ?? "-"} km
                            </div>
                            <hr />
                            <div>
                                <strong>{date}</strong>
                            </div>
                            {reservation.note ? (
                                <>
                                    <hr />
                                    <div>
                                        <Markdown>{reservation.note}</Markdown>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                            {reservation.odometerInitial && reservation.odometerFinal ? (
                                <>
                                    <hr />
                                    <div>
                                        {reservation.odometerInitial} km - {reservation.odometerFinal} km (
                                        {reservation.odometerFinal - reservation.odometerInitial}) km
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    )

                    if (reservation.startTime < new Date()) {
                        reservationData.firstButtonText = this.fm("fleet_reservation.record_odometer")
                        reservationData.firstButtonAction = () => this.recordOdometer(reservation, fleetCar)
                    } else {
                        reservationData.firstButtonText = this.fm("reserve.cancel_reservation")
                        reservationData.firstButtonAction = () => this.cancelReservation(reservation)
                    }
                    reservationsData.push(reservationData)
                }
            }
        }

        const reservationControlBlock = reservationsData.map((data, i) => (
            <Row className="mx-auto my-3 reservations-panel" key={i}>
                <Fade className="w-100 text-center">{data.topElement}</Fade>
                <div className="mt-3 mx-auto">
                    <button
                        type="submit"
                        className="btn btn-primary hm-btn m-2"
                        hidden={!data.firstButtonText}
                        onClick={data.firstButtonAction}
                        disabled={this.state.actionInProgress}
                    >
                        {data.firstButtonText}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary hm-btn m-2"
                        hidden={!data.secondButtonText}
                        onClick={data.secondButtonAction}
                        disabled={this.state.actionInProgress}
                    >
                        {data.secondButtonText}
                    </button>
                </div>
            </Row>
        ))

        return (
            <div>
                {(this.props.user.fleets ?? []).length > 1 ? (
                    <div>
                        <select
                            className="mb-3 select_arrow y-style"
                            name="fleet"
                            id="fleet"
                            value={this.props.user.fleets![0].id}
                            onChange={this.onSelectChange}
                        >
                            {this.props.user.fleets?.map(fleet => (
                                <option key={fleet.id} value={fleet.id}>
                                    {fleet.title}
                                </option>
                            ))}
                        </select>
                    </div>
                ) : (
                    <div></div>
                )}
                <Row className="m-0">
                    <Col className="text-center">
                        <DayPicker
                            onDayClick={this.handleDayClick}
                            selected={this.state.selectedDay}
                            showOutsideDays
                            modifiers={modifiers}
                            modifiersClassNames={modifiersClassNames}
                            locale={this.props.intl.locale === "cs" ? cs : enUS}
                        />
                    </Col>
                    <Col className="d-flex align-items-center flex-column justify-content-center text-center">{reservationControlBlock}</Col>
                </Row>
            </div>
        )
    }

    halfdayReservationFlow = () => {
        const select = (
            <select className="mb-1 select_arrow y-style" name="period" id="period" defaultValue="day">
                <option key={"am"} value={"am"}>
                    {this.fm("reserve.morning")}
                </option>
                <option key={"pm"} value={"pm"}>
                    {this.fm("reserve.afternoon")}
                </option>
                <option key={"day"} value={"day"}>
                    {this.fm("reserve.whole_day")}
                </option>
                <option key={"other"} value={"other"}>
                    {this.fm("reserve.other")}
                </option>
            </select>
        )
        return MySwal.fire({
            title: this.fm("reserve.reservation_time"),
            html: select,
            confirmButtonText: this.fm("general.next") + " &rarr;",
            showCancelButton: true,
            preConfirm: () => {
                return (document!.getElementById("period") as HTMLInputElement).value
            }
        }).then(result => {
            if (result.isDismissed) {
                //dismissed dialog
                return Promise.reject(new Error("closed"))
            }
            let startTime = moment.tz(this.state.selectedDay, this.timezone).startOf("d")
            let endTime = moment.tz(this.state.selectedDay, this.timezone).endOf("d")
            if (result.value === "am") {
                endTime = moment.tz(this.state.selectedDay, this.timezone).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
            } else if (result.value === "pm") {
                startTime = moment.tz(this.state.selectedDay, this.timezone).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
            } else if (result.value === "other") {
                return null
            }
            return { startTime, endTime }
        })
    }

    onEndDateChange = (date: string | moment.Moment | null) => {
        if (date === null) {
            return
        }
        const endDate = this.onDateChange(date)
        if (endDate) {
            this.endDate = endDate
        }
    }
    onDateChange = (date: moment.Moment | string) => {
        const filterDate = moment.isMoment(date) ? date : moment(date)
        if (filterDate.isValid()) {
            return filterDate
        } else {
            return null
        }
    }

    minutesReservationFlow = async () => {
        const startTime = await this.timeSwal(this.fm("reserve.start_time"), "08:00", this.state.selectedDay)

        const endDateResult = await MySwal.fire({
            title: "Select reservation end date",
            html: (
                <StaticDatePicker
                    date={moment(this.state.selectedDay)}
                    onChange={this.onEndDateChange}
                    timezone={this.timezone}
                    locale={this.props.intl.locale}
                    theme={muiTheme}
                />
            ),
            confirmButtonText: this.fm("general.next") + " &rarr;",
            showCancelButton: true
        })
        if (!endDateResult.value) {
            throw new Error("closed")
        }

        const endTime = await this.timeSwal(this.fm("reserve.end_time"), "16:00", this.endDate.toDate())

        return { startTime, endTime }
    }

    loadingSwal = () => {
        MySwal.fire({
            didOpen: () => {
                Swal.showLoading()
            }
        })
    }

    noteSwal = async (defaultValue: string = ""): Promise<string> => {
        const result = await MySwal.fire({
            title: this.fm("reserve.enter_note"),
            html: (
                <div>
                    {this.fm("reserve.use_markdown")}{" "}
                    <a href="https://www.markdownguide.org/basic-syntax/" target="_blank" rel="noreferrer">
                        markdown
                    </a>
                </div>
            ),
            input: "textarea",
            inputValue: defaultValue,
            inputPlaceholder: "**Odkaz** na [technický průkaz](https://bit.ly/4f9mWXc)",
            customClass: { input: "y-style" },
            confirmButtonText: this.fm("general.next") + " &rarr;",
            showCancelButton: true
        })
        if (result.value === undefined) {
            throw new Error("closed")
        }
        return result.value as string
    }

    odometerSwal = async (title: string, text: string, defaultValue?: number): Promise<number> => {
        const result = await MySwal.fire({
            title: title,
            text: text,
            input: "number",
            inputValue: defaultValue,
            customClass: { input: "y-style" },
            confirmButtonText: this.fm("general.next") + " &rarr;",
            showCancelButton: true
        })
        if (!result.value) {
            throw new Error("closed")
        }
        return parseInt(result.value)
    }

    timeSwal = async (title: string, defaultValue: string = "08:00", baseDate: Date): Promise<moment.Moment> => {
        const result = await MySwal.fire({
            title: title,
            html: <input id="time-input" className="y-style" defaultValue={defaultValue} type="time" />,
            confirmButtonText: this.fm("general.next") + " &rarr;",
            showCancelButton: true,
            preConfirm: () => {
                return (document!.getElementById("time-input") as HTMLInputElement).value
            }
        })
        if (result.isDismissed) {
            return Promise.reject(new Error("closed"))
        }
        return Utilities.parseTime(result.value!, baseDate, this.timezone)
    }
}

const ReserveSpot = (props: any) => {
    const intl = useIntl()
    return (
        <div className="parkfair-container">
            <h3>
                <M id="nav.fleet_reservation" />
            </h3>
            <hr className="d-none d-md-block" />
            <UserStateCheck>
                {userInfo => (
                    <Fade>
                        <ReserveFleetCarBase user={userInfo.user} {...props} intl={intl} />
                    </Fade>
                )}
            </UserStateCheck>
        </div>
    )
}

export default withFirebase(ReserveSpot)
