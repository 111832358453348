import { initializeApp } from "firebase/app"
import {
    Auth,
    getAuth,
    createUserWithEmailAndPassword,
    sendEmailVerification,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    applyActionCode,
    verifyPasswordResetCode,
    confirmPasswordReset,
    NextOrObserver,
    User as FIRUser,
    onAuthStateChanged,
    OAuthProvider,
    getRedirectResult,
    signInWithRedirect
} from "firebase/auth"
import {
    initializeFirestore,
    Firestore,
    Timestamp,
    setDoc,
    doc,
    getDoc,
    getDocs,
    query,
    collection,
    deleteDoc,
    addDoc,
    where,
    updateDoc,
    collectionGroup,
    orderBy,
    startAt,
    endAt,
    serverTimestamp
} from "firebase/firestore"
import { FirebaseStorage, getDownloadURL, getStorage, ref, uploadString } from "firebase/storage"
import moment from "moment-timezone"
import "../Firebase/Models/extensions"
//import 'moment/locale/cs'
//import geohash from 'ngeohash';
import { Reservation, User, Car, ParkingSpot, AbuseInfo, ApiError, Location, Collections, Company, PermanentReservation, EngineType } from "."

import { firebaseConfig } from "../../constants/config.js"
import { FleetCar } from "./Models/fleetCar"
import { FleetReservation } from "./Models/fleetReservation"
import { Fleet } from "./Models/fleet"

class Firebase {
    private firestore: Firestore
    private auth: Auth
    private storage: FirebaseStorage

    constructor() {
        const app = initializeApp(firebaseConfig)

        this.firestore = initializeFirestore(app, { experimentalAutoDetectLongPolling: true })
        this.auth = getAuth(app)
        this.storage = getStorage(app)
    }

    // *** Auth API ***

    createUserWithEmailAndPassword = (email: string, password: string) => {
        return createUserWithEmailAndPassword(this.auth, email, password).then(authUser => {
            sendEmailVerification(authUser.user)
            return Promise.resolve(authUser)
        })
    }

    deleteAuthUser = (authUser: FIRUser) => {
        return authUser.delete()
    }

    sendVerificationEmail = () => {
        if (this.auth.currentUser) {
            sendEmailVerification(this.auth.currentUser)
        }
    }

    signInWithEmailAndPassword = (email: string, password: string) => signInWithEmailAndPassword(this.auth, email, password)

    singInWithSSO = (providerId: string, email: string) => {
        const provider = new OAuthProvider(providerId)
        provider.setCustomParameters({
            login_hint: email
        })
        //TODO probably remove this (okta specifics)
        //provider.addScope('okta.users.read.self')
        provider.addScope("email")
        provider.addScope("profile")
        provider.addScope("address")
        return signInWithRedirect(this.auth, provider)
    }

    getRedirectResult = () => {
        return getRedirectResult(this.auth)
    }

    signOut = () => this.auth.signOut()

    passwordReset = (email: string) => sendPasswordResetEmail(this.auth, email)

    verifyEmail = (actionCode: string) => applyActionCode(this.auth, actionCode)

    verifyPasswordResetCode = (actionCode: string) => verifyPasswordResetCode(this.auth, actionCode)

    confirmPasswordReset = (actionCode: string, newPassword: string) => confirmPasswordReset(this.auth, actionCode, newPassword)

    onAuthStateChanged = (nextOrObserver: NextOrObserver<FIRUser>) => onAuthStateChanged(this.auth, nextOrObserver)

    // *** User data API ***
    fetchUserData = (userId: string) => {
        const ref = doc(this.firestore, Collections.Users, userId)
        return getDoc(ref).then(doc => {
            if (doc.exists()) {
                const user = User.fromData(doc)
                return Promise.resolve(user)
            } else {
                return Promise.reject(new ApiError(`Getting user ${userId} failed, empty data`))
            }
        })
    }

    fetchUserPrivateData = async (user: User) => {
        const ref = doc(this.firestore, Collections.Users, user.uid, Collections.UserPrivate, Collections.UserContactData)
        const snap = await getDoc(ref)
        if (snap.exists()) {
            const data = snap.data()!
            user.email = data.email
            user.phone = data.phone
            user.surname = data.surname
        }
        return user
    }

    fetchUserDataWithCars = async (userId: string, includePrivate: boolean = false) => {
        const [userResult, carResult] = await Promise.allSettled([this.fetchUserData(userId), this.fetchCarsForUserId(userId)])
        if (userResult.status === "rejected") {
            console.log("Fetching user with cars failed: ", userResult.reason)
            return Promise.resolve(null)
        } else if (carResult.status === "rejected") {
            console.log("Fetching user with cars failed: ", carResult.reason)
            return Promise.resolve(null)
        }
        const user = userResult.value
        if (user && user.locationIds) {
            if (includePrivate) {
                try {
                    await this.fetchUserPrivateData(user)
                } catch (error) {
                    console.log(`Error fetching private data of user ${user.uid}: `, error)
                }
            }
            user.cars = carResult.value

            const locations = await Promise.all(user.locationIds.map(this.fetchLocation))
            user.locations = locations.filterUndef()

            const fleets = await Promise.all(user.fleetIds.map(this.fetchFleet))
            user.fleets = fleets.filterUndef()

            return Promise.resolve(user)
        }
        return Promise.resolve(null)
    }

    setAuthLocale = (locale: string) => {
        this.auth.languageCode = locale
    }

    setUser = async (user: User) => {
        const ref = doc(this.firestore, Collections.Users, user.uid)
        await setDoc(
            ref,
            {
                timestampUpdated: serverTimestamp(),
                name: user.name,
                surname: user.surname.substring(0, 1),
                email: "",
                company: user.company ?? "",
                priority: user.priority ?? 0,
                locations: user.locationIds ?? null,
                fleets: user.fleetIds ?? null,
                visitorCompany: user.visitorCompany ?? null,
                host: user.host ?? null
            },
            { merge: true }
        ).catch(error => {
            console.log("Error setting user: ", error)
        })
        const privateDataRef = doc(this.firestore, Collections.Users, user.uid, Collections.UserPrivate, Collections.UserContactData)
        await setDoc(
            privateDataRef,
            {
                timestampUpdated: serverTimestamp(),
                surname: user.surname,
                phone: user.phone ?? "",
                email: user.email
            },
            { merge: true }
        ).catch(error => {
            console.log("Error setting user private data: ", error)
        })
    }

    setUserLocale = (userId: string, locale: string) => {
        const ref = doc(this.firestore, Collections.Users, userId)
        return setDoc(
            ref,
            {
                locale: locale
            },
            { merge: true }
        )
    }

    setUserActiveLocation = (user: User, locationId: string, providedLocations: Location[] | undefined = undefined) => {
        const oldLocations = user.locationIds ?? []
        let locations = [locationId]
        locations = locations.concat(oldLocations.filter(l => l !== locationId))
        const locationsData = [...(user.locations ?? []), ...(providedLocations ?? [])]
        user.locations = locations
            .map(locationId => locationsData.find(l => l.code === locationId))
            .filter(l => l)
            .map(l => l!)
        const ref = doc(this.firestore, Collections.Users, user.uid)
        return setDoc(
            ref,
            {
                locations: locations
            },
            { merge: true }
        )
    }

    deleteUser = (userId: string) => {
        const ref = doc(this.firestore, Collections.Users, userId)
        return setDoc(
            ref,
            {
                deleted: true
            },
            { merge: true }
        )
    }

    getUsersLastActiveTime = (user: User) => {
        return user.lastActiveGarageWeb
    }

    setUsersLastActiveTime = (uid: string) => {
        const ref = doc(this.firestore, Collections.Users, uid)
        return setDoc(
            ref,
            {
                lastActiveGarageWeb: serverTimestamp()
            },
            { merge: true }
        ).catch(error => {
            console.log("Error setting user: ", error)
        })
    }

    //In out optional user gets filled with cars if provided
    fetchCarsForUserId = (userId: string, user: User | undefined = undefined) => {
        const q = query(collection(this.firestore, Collections.Users, userId, Collections.Cars))
        return getDocs(q).then((querySnapshot: any) => {
            let cars: Car[] = []
            querySnapshot.forEach((doc: any) => {
                const car = Car.fromData(doc)
                if (car) {
                    cars.push(car)
                }
            })
            if (user) user.cars = cars
            return Promise.resolve(cars)
        })
    }

    //In out optional user gets filled with cars if provided
    fetchAuthUserForUserId = (userId: string, user: User | undefined = undefined) => {
        const q = query(collection(this.firestore, Collections.Users, userId, Collections.Cars))
        return getDocs(q).then((querySnapshot: any) => {
            let cars: Car[] = []
            querySnapshot.forEach((doc: any) => {
                const car = Car.fromData(doc)
                if (car) {
                    cars.push(car)
                }
            })
            if (user) user.cars = cars
            return Promise.resolve(cars)
        })
    }

    deleteCarForUserId = (carId: string, userId: string) => {
        const ref = doc(this.firestore, Collections.Users, userId, Collections.Cars, carId)
        return deleteDoc(ref)
    }

    fetchCarForUserId = (carId: string, userId: string) => {
        const ref = doc(this.firestore, Collections.Users, userId, Collections.Cars, carId)
        return getDoc(ref).then(Car.fromData)
    }

    generateCarId = (userId: string) => {
        return doc(collection(this.firestore, Collections.Users, userId, Collections.Cars)).id
    }

    createCar = (car: Car, userId: string) => {
        const carId = car.id ? car.id : this.generateCarId(userId)
        const ref = doc(this.firestore, Collections.Users, userId, Collections.Cars, carId)
        return setDoc(
            ref,
            {
                timestampCreated: serverTimestamp(),
                plateNum: car.plateNum,
                brand: car.brand ?? null,
                model: car.model ?? null,
                color: car.color ?? null,
                engineType: car.engineType
            },
            { merge: true }
        )
    }

    fetchAllUsers = async (companyCode: string) => {
        const q = query(collection(this.firestore, Collections.Users), where("company", "==", companyCode))
        const querySnapshot = await getDocs(q)

        const users = querySnapshot.docs
            .map(User.fromData)
            .filter(u => u)
            .map(u => u!)

        await Promise.all(users.map(this.fetchUserPrivateData))

        return users.filter(u => !u.deleted)
    }

    lookupLicense = async (licensePlate: string): Promise<[User, Car] | null> => {
        if (licensePlate.length < 4) {
            return null
        }
        const licenseStart = licensePlate.substring(0, 3).toUpperCase()
        const q = query(collectionGroup(this.firestore, Collections.Cars), orderBy("plateNum"), startAt(licenseStart), endAt(licenseStart + "~"))
        const snapshot = await getDocs(q)
        if (snapshot.empty) {
            return null
        }

        const licenseEnd = licensePlate.substring(licensePlate.length - 4).toUpperCase()
        let car: Car | null = null
        let userId: string | null = null
        snapshot.forEach(doc => {
            const tmpCar = Car.fromData(doc)
            if (tmpCar?.plateNum.toUpperCase().endsWith(licenseEnd)) {
                car = tmpCar
                userId = doc.ref.parent!.parent!.id
            }
        })

        if (car) {
            const user = await this.fetchUserData(userId!)
            return [user!, car!]
        } else {
            return null
        }
    }

    generateUserId = () => {
        return doc(collection(this.firestore, Collections.Users)).id
    }

    // *** Admin ***
    fetchAdminPrivileges = async (
        userId: string,
        user: User | undefined = undefined
    ): Promise<{ locationsIds: string[]; companies: string[]; fleetIds: string[] }> => {
        const ref = doc(this.firestore, Collections.Admins, userId)
        const querySnapshot = await getDoc(ref)
        if (!querySnapshot.exists()) {
            return { locationsIds: [], companies: [], fleetIds: [] }
        }
        const data = querySnapshot.data()
        if (user) {
            user.adminLocations = data?.locations ?? []
            user.adminCompanies = data?.companies ?? []
            user.adminFleets = data?.fleets ?? []
        }
        return { locationsIds: data?.locations ?? [], companies: data?.companies ?? [], fleetIds: data?.fleets ?? [] }
    }

    setAdminLocations = (user: User, companiesIds: string[], locationsIds: string[], fleetIds: string[]) => {
        const ref = doc(this.firestore, Collections.Admins, user.uid)
        if (locationsIds.length === 0) {
            companiesIds = []
        } else if (companiesIds.length === 0) {
            companiesIds = [user.company]
        }
        return setDoc(
            ref,
            {
                companies: companiesIds,
                locations: locationsIds,
                fleets: fleetIds,
                email: user.email
            },
            { merge: true }
        )
    }

    // *** Locations ***
    fetchLocation = async (locationCode: string) => {
        if (locationCode.substring(0, 7) === "visitor") {
            return null
        }
        const ref = doc(this.firestore, Collections.Parking, locationCode)
        const documentSnapshot = await getDoc(ref)
        return Location.fromData(documentSnapshot)
    }

    // *** Companies ***
    fetchCompany = async (companyCode: string) => {
        const ref = doc(this.firestore, Collections.Companies, companyCode)
        const documentSnapshot = await getDoc(ref)
        return Company.fromData(documentSnapshot)
    }

    // *** Reservations API ***
    setReservation = (location: Location, reservation: Reservation, sendEmail?: boolean) => {
        const col = collection(this.firestore, Collections.Parking, location.code, Collections.Reservations)
        const reservationId = reservation.id ?? doc(col).id
        const ref = doc(col, reservationId)
        let data = reservation.toDict(location.timezone)
        data.sendEmail = sendEmail ?? false
        return setDoc(ref, data, { merge: true })
    }

    addPermanentReservation = (location: Location, reservation: PermanentReservation) => {
        const ref = collection(this.firestore, Collections.Parking, location.code, Collections.PermanentReservations)
        return addDoc(ref, reservation.toDict(location.timezone))
    }

    fetchAllReservations = async (location: Location, startDate: Date, endDate: Date, userId?: string) => {
        const [dayRes, permanentRes] = await Promise.all([
            this.fetchDayReservations(location, startDate, endDate, userId),
            this.fetchPermanentReservations(location, userId)
        ])
        const expandedPermanentReservations = permanentRes.map(r => this.expandPermanentReservation(location, r, startDate, endDate)).flat()

        const filteredPermanent = expandedPermanentReservations
            .filter(permanentRes => permanentRes.endTime > startDate && permanentRes.startTime < endDate)
            .filter(
                permanentRes =>
                    !dayRes.some(
                        r =>
                            r.userId === permanentRes.userId &&
                            r.parkingSpotId === permanentRes.parkingSpotId &&
                            moment.tz(r.startTime, location.timezone).isSame(moment.tz(permanentRes.startTime, location.timezone), "minute")
                    )
            )
        return [dayRes, filteredPermanent].flat()
    }

    fetchAllReservationsWithUsers = async (location: Location, startDate: Date, endDate: Date, includePrivate: boolean = false) => {
        const reservations = await this.fetchAllReservations(location, startDate, endDate)
        return await this.fetchUsersForReservations(reservations, includePrivate)
    }

    fetchUsersForReservations = <R extends Reservation | FleetReservation>(reservations: R[], includePrivate: boolean = false) => {
        const userIds = Array.from(new Set(reservations.map(reservation => reservation.userId)))
        const promises = userIds.map(id => this.fetchUserDataWithCars(id, includePrivate))
        return Promise.all(promises).then(users => {
            for (const reservation of reservations) {
                reservation.user = users.find(user => user?.uid === reservation.userId) ?? undefined
            }
            return reservations
        })
    }

    fetchPermanentReservations = async (location: Location, userId?: string) => {
        let q = query(collection(this.firestore, Collections.Parking, location.code, Collections.PermanentReservations))
        if (userId) {
            q = query(q, where("userId", "==", userId))
        }

        const querySnapshot = await getDocs(q)
        const reservations = querySnapshot.docs
            .map(PermanentReservation.fromData)
            .filter(r => r)
            .map(r => r!)
        return reservations.filter(r => !r.underlyingReservation.canceled)
    }

    fetchDayReservations = async (location: Location, startDate: Date, endDate: Date, userId?: string): Promise<Reservation[]> => {
        let q = query(collection(this.firestore, Collections.Parking, location.code, Collections.Reservations))
        if (userId) {
            q = query(q, where("userId", "==", userId))
        }
        q = query(q, where("endTime", ">", startDate), where("startTime", "<", endDate))

        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(Reservation.fromData).filterUndef()
    }

    fetchReservationId = (locationCode: string, reservationId: string) => {
        const ref = doc(this.firestore, Collections.Parking, locationCode, Collections.Reservations, reservationId)
        return getDoc(ref).then(documentSnapshot => {
            return new Promise<Reservation | null>(resolve => resolve(Reservation.fromData(documentSnapshot)))
        })
    }

    expandPermanentReservation = (location: Location, permanentReservation: PermanentReservation, startDate: Date, endDate: Date): Reservation[] => {
        const firstDay = [startDate, permanentReservation.validFrom]
            .filter(d => d)
            .map(d => d!)
            .sort((a, b) => b.getTime() - a.getTime())[0]
        const lastDay = [endDate, permanentReservation.validTo]
            .filter(d => d)
            .map(d => d!)
            .sort((a, b) => a.getTime() - b.getTime())[0]

        let startHours = permanentReservation.underlyingReservation.startTime
        let endHours = permanentReservation.underlyingReservation.endTime

        let result: Reservation[] = []
        const daysCount = moment(lastDay).diff(firstDay, "days")

        const firstDayMoment = moment.tz(firstDay, location.timezone).set({ hour: 10, minute: 0, second: 0, millisecond: 0 })
        for (let i = 0; i <= daysCount; i++) {
            const day = moment(firstDayMoment).add(i, "days")
            const reservation = new Reservation({ ...permanentReservation.underlyingReservation })
            reservation.id = doc(collection(this.firestore, Collections.Parking, location.code, Collections.Reservations)).id
            reservation.date = day.toDate()

            const startTime = day.toDate()
            startTime.setHours(startHours.getHours(), startHours.getMinutes(), startHours.getSeconds(), 0)
            reservation.startTime = startTime

            const endTime = day.toDate()
            endTime.setHours(endHours.getHours(), endHours.getMinutes(), endHours.getSeconds(), 0)
            reservation.endTime = endTime

            reservation.confirmed = true
            result.push(reservation)
        }
        return result
    }

    /// Cancels reservations. If needed, this creates a new reservation. This is used for permanent reservations - virtual reservation is turned into a real one.
    cancelReservation = (location: Location, reservation: Reservation) => {
        reservation.canceled = true
        const ref = doc(this.firestore, Collections.Parking, location.code, Collections.Reservations, reservation.id!)
        return setDoc(
            ref,
            {
                canceled: true,
                date: Timestamp.fromDate(reservation.timeZoneAwareDate(location.timezone)),
                startTime: Timestamp.fromDate(reservation.startTime),
                endTime: Timestamp.fromDate(reservation.endTime),
                userId: reservation.userId,
                parkingSpot: reservation.parkingSpot ?? null,
                parkingSpotId: reservation.parkingSpotId ?? null,
                dateCanceled: new Date(),
                priority: reservation.priority ?? null,
                timestampCreated: serverTimestamp()
            },
            { merge: true }
        ).catch(error => {
            console.warn("Error updating reservation: ", error)
        })
    }

    cancelReservationId = (locationCode: string, reservationId: string) => {
        const ref = doc(this.firestore, Collections.Parking, locationCode, Collections.Reservations, reservationId)
        return updateDoc(ref, {
            canceled: true,
            dateCanceled: new Date()
        }).catch(error => {
            console.warn("Error updating reservation: ", error)
        })
    }

    cancelPermanentReservation = (locationCode: string, reservation: Reservation) => {
        const ref = doc(this.firestore, Collections.Parking, locationCode, Collections.PermanentReservations, reservation.id!)
        return updateDoc(ref, {
            canceled: true,
            dateCanceled: new Date()
        }).catch(error => {
            console.warn("Error updating permanent reservation: ", error)
        })
    }

    fetchAvailableSpots = (location: Location, startDate: Date, endDate: Date, includeDisabled: boolean = false): Promise<ParkingSpot[]> => {
        return Promise.all([this.fetchParkingSpots(location.code, includeDisabled), this.fetchAllReservations(location, startDate, endDate)]).then(
            ([spots, reservations]) => {
                const bookedSpotIds: string[] = reservations.filter(r => r.parkingSpotId && !r.canceled).map(r => r.parkingSpotId!)
                const availableSpots = spots.filter(spot => bookedSpotIds.find(bookedSpotId => spot.id === bookedSpotId) === undefined)
                availableSpots.sort(ParkingSpot.compare)
                return Promise.resolve(availableSpots)
            }
        )
    }

    // *** ParkingSpots API ***
    fetchParkingSpots = (locationCode: string, includeDisabled: boolean = false) => {
        let q = query(collection(this.firestore, Collections.Parking, locationCode, Collections.Spots))
        return getDocs(q).then(querySnapshot => {
            const spots: ParkingSpot[] = []
            querySnapshot.forEach(doc => {
                const spot = ParkingSpot.fromData(doc)
                if (spot && (includeDisabled || spot.enabled)) {
                    spots.push(spot)
                }
            })
            return Promise.resolve(spots)
        })
    }

    addParkingSpot = (locationCode: string, spotName: string, electric: boolean) => {
        let ref = collection(this.firestore, Collections.Parking, locationCode, Collections.Spots)
        return addDoc(ref, {
            name: spotName,
            electric: electric
        })
    }

    setParkingSpot = (locationCode: string, spot: ParkingSpot) => {
        const ref = doc(this.firestore, Collections.Parking, locationCode, Collections.Spots, spot.id)
        return setDoc(
            ref,
            {
                timestampUpdated: serverTimestamp(),
                name: spot.name,
                electric: spot.electric,
                attractivity: spot.attractivity,
                naturalGasAllowed: spot.naturalGasAllowed,
                isEnabled: spot.enabled,
                inReserve: spot.inReserve
            },
            { merge: true }
        )
    }

    removeParkingSpot = (locationCode: string, spotId: string) => {
        const ref = doc(this.firestore, Collections.Parking, locationCode, Collections.Spots, spotId)
        return deleteDoc(ref)
    }

    // *** Abuse API ***
    uploadAbuseImage = (locationCode: string, imageData: string, abuseId: string) => {
        const storageRef = ref(this.storage, "parkingAbuses/" + locationCode + "/" + abuseId)
        return uploadString(storageRef, imageData, "data_url")
    }

    getAbuseImageLink = (locationCode: string, abuseId: string) => {
        const storageRef = ref(this.storage, "parkingAbuses/" + locationCode + "/" + abuseId)
        return getDownloadURL(storageRef).catch(e => undefined)
    }

    addParkingAbuse = async (locationCode: string, abuse: AbuseInfo, imageData?: string) => {
        const abuseRef = doc(collection(this.firestore, Collections.Parking, locationCode, Collections.Abuses))

        if (imageData) {
            await this.uploadAbuseImage(locationCode, imageData, abuseRef.id)
        }
        return await setDoc(abuseRef, abuse.asData(true))
    }

    fetchAllAbuses = async (locationCode: string) => {
        let q = query(collection(this.firestore, Collections.Parking, locationCode, Collections.Abuses))
        const snapshot = await getDocs(q)
        const abuses = snapshot.docs.map(doc => AbuseInfo.fromData(doc))
        return abuses.filter(a => a).map(a => a!)
    }

    // *** Slack ***
    updateSlackData = async (userId: string, accessToken: string, slackUserId: string) => {
        const ref = doc(this.firestore, Collections.Users, userId)
        return updateDoc(ref, {
            slackData: {
                accessToken,
                userId: slackUserId
            }
        })
    }

    // *** Fleet API ***
    fetchFleet = async (fleetId: string) => {
        const ref = doc(this.firestore, Collections.Fleet, fleetId)
        const documentSnapshot = await getDoc(ref)
        return Fleet.fromData(documentSnapshot)
    }

    setUserActiveFleet = (user: User, fleetId: string, providedFleets: Fleet[] | undefined = undefined) => {
        const oldFleets = user.fleetIds ?? []
        let fleets = [fleetId]
        fleets = fleets.concat(oldFleets.filter(l => l !== fleetId))
        const fleetsData = [...(user.fleets ?? []), ...(providedFleets ?? [])]
        user.fleets = fleets.map(fleetId => fleetsData.find(f => f.id === fleetId)).filterUndef()
        user.fleetIds = user.fleets.map(f => f.id)
        const ref = doc(this.firestore, Collections.Users, user.uid)
        const data = { fleets: fleets }
        return setDoc(ref, data, { merge: true })
    }

    fetchAllFleetCars = async (fleetId: string) => {
        let q = query(collection(this.firestore, Collections.Fleet, fleetId, Collections.FleetCars))
        const snapshot = await getDocs(q)
        const cars = snapshot.docs.map(doc => FleetCar.fromData(doc))
        return cars.filterUndef()
    }

    fetchAllFleetReservations = async (fleetId: string, startDate: Date, endDate: Date, userId?: string) => {
        let q = query(collection(this.firestore, Collections.Fleet, fleetId, Collections.FleetReservations))
        if (userId) {
            q = query(q, where("userId", "==", userId))
        }
        q = query(q, where("endTime", ">", startDate), where("startTime", "<", endDate))

        const querySnapshot = await getDocs(q)
        return querySnapshot.docs.map(FleetReservation.fromData).filterUndef()
    }

    fetchAllFleetReservationsWithUsers = async (fleetId: string, startDate: Date, endDate: Date, includePrivate: boolean = false) => {
        const reservations = await this.fetchAllFleetReservations(fleetId, startDate, endDate)
        return await this.fetchUsersForReservations(reservations, includePrivate)
    }

    updateFleetReservation = async (fleetId: string, fleetReservationId: string, data: { [key: string]: any }) => {
        const ref = doc(this.firestore, Collections.Fleet, fleetId, Collections.FleetReservations, fleetReservationId)
        return updateDoc(ref, data)
    }

    fetchAvailableCars = async (fleetId: string, startDate: Date, endDate: Date): Promise<FleetCar[]> => {
        const [fleetCars, fleetReservations] = await Promise.all([
            this.fetchAllFleetCars(fleetId),
            this.fetchAllFleetReservations(fleetId, startDate, endDate)
        ])
        const bookedCarIds = fleetReservations.filter(r => r.fleetCarId && !r.canceled).map(r => r.fleetCarId!)
        const availableCars = fleetCars.filter(car => bookedCarIds.find(bookedCarId => car.id === bookedCarId) === undefined)
        availableCars.sort((a, b) => a.title.localeCompare(b.title))

        return availableCars
    }

    addFleetCar = (fleetId: string, carTitle: string, plateNum: string, engineType: EngineType, note: string, odometer: number) => {
        let ref = doc(collection(this.firestore, Collections.Fleet, fleetId, Collections.FleetCars))
        const fleetCar = new FleetCar({ id: ref.id, title: carTitle, plateNum, engineType, note, timestampCreated: new Date(), odometer: odometer })
        return setDoc(ref, fleetCar.asData())
    }

    updateFleetCar = async (fleetId: string, fleetCarId: string, data: { [key: string]: any }) => {
        const ref = doc(this.firestore, Collections.Fleet, fleetId, Collections.FleetCars, fleetCarId)
        return updateDoc(ref, data)
    }

    removeFleetCar = (fleetId: string, fleetCarId: string) => {
        const ref = doc(this.firestore, Collections.Fleet, fleetId, Collections.FleetCars, fleetCarId)
        return deleteDoc(ref)
    }
}

export default Firebase
export { Timestamp }

export const areSameDay = (first: Date, second: Date = new Date()) =>
    first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate()
