export enum Collections {
    Users = "users",
    Admins = "admins",
    Cars = "cars",
    Cards = "cards",
    Reservations = "reservations",
    PermanentReservations = "permanentReservations",
    Parking = "parking",
    Spots = "spots",
    Abuses = "abuses",
    Companies = "companies",
    EmailDestinations = "emailDestinations",
    Connections = "connections",
    Rides = "rides",
    RecurringRides = "recurringRides",
    RideExceptions = "recRidesExc",
    EventIds = "eventIds",
    OccupancyCache = "occupancyCache",
    Routes = "routes",
    RoutesCache = "routesCache",
    Matches = "matches",
    UserPrivate = "private",
    UserPrivateAccess = "privateAccess",
    UserContactData = "contactInfo",
    AdditionalData = "additionalData",
    VisitorsEmailData = "visitorsEmailData",
    Fleet = "fleet",
    FleetCars = "cars",
    FleetReservations = "reservations"
}

export class Language {
    constructor(public id: string, public title: string) {}
}

export class Email {
    constructor(public destination: string, public subject: string, public body: string) {}
}

export enum FcmTokenType {
    Garage = "fcmPfToken",
    Carpool = "fcmToken"
}

export class Notification {
    constructor(public title: string, public body: string, public token: string, public senderId: string, public tokenType: FcmTokenType) {}
}

export class SlackNotification {
    constructor(public title: string, public body: string, public accessToken: string, public slackUserId: string) {}
}

export class OccupancyData {
    constructor(public remainingSpots: number, public confirmedReservations: number, public pendingReservations: number) {}
}

export class Place {
    name: string
    lat: number
    lng: number
    placeId?: string

    constructor(name: string, lat: number, lng: number, placeId?: string) {
        const precision = 100000
        this.name = name
        this.lat = Math.round(lat * precision) / precision
        this.lng = Math.round(lng * precision) / precision
        this.placeId = placeId
    }

    asWaypoint = () => {
        if (this.placeId !== undefined) {
            return {
                placeId: this.placeId
            }
        }
        return {
            location: {
                latLng: {
                    latitude: this.lat,
                    longitude: this.lng
                }
            }
        }
    }

    asData = () => {
        const dict: { [k: string]: any } = {
            name: this.name,
            latitude: this.lat,
            longitude: this.lng
        }
        if (this.placeId) {
            dict.placeId = this.placeId
        }
        return dict
    }
}

export enum Direction {
    Forward = 0,
    Backward = 1
}

export class RouteCache {
    timestampCreated: Date = new Date()

    constructor(public duration: number, public originLat: number, public originLng: number, public destLat: number, public destLng: number) {}
}
