import { DocumentSnapshot, Timestamp } from "../firestoreImports"
import { EngineType } from "./car"
import { z } from "zod"

const fleetCarSchema = z.object({
    title: z.string(),
    plateNum: z.string(),
    timestampCreated: z.custom<Timestamp>(),
    engineType: z.custom<EngineType>(),
    note: z.string().optional(),
    odometer: z.number()
})

interface IFleetCar {
    id: string
    title: string
    timestampCreated: Date
    plateNum: string
    engineType: EngineType
    note?: string
    odometer: number
}
export interface FleetCar extends IFleetCar {}
export class FleetCar {
    constructor(params: IFleetCar) {
        Object.assign(this, params)
    }

    static fromData(doc: DocumentSnapshot): FleetCar | null {
        const data = doc.data()
        if (!data) {
            console.warn("Cannot create FleetCar from snapshot")
            return null
        }
        try {
            const parsed = fleetCarSchema.parse(data)
            return new FleetCar({
                id: doc.id,
                title: parsed.title,
                plateNum: parsed.plateNum,
                timestampCreated: parsed.timestampCreated.toDate(),
                engineType: parsed.engineType,
                note: parsed.note,
                odometer: parsed.odometer
            })
        } catch (error) {
            console.log(`Cannot create fleet car ${doc.id} from snapshot: ${error}`)
            return null
        }
    }

    asData(): { [k: string]: string | Date | number | undefined } {
        return {
            plateNum: this.plateNum,
            timestampCreated: this.timestampCreated ?? new Date(),
            title: this.title,
            engineType: this.engineType,
            note: this.note,
            odometer: this.odometer
        }
    }

    public toString = (): string => {
        return `${this.title} (${this.plateNum})`
    }
}
