import React, { FC, ReactElement, ReactNode, useEffect, useState } from "react"
import { Fade } from "react-awesome-reveal"
import moment from "moment"
import "styles/react-datetime.css"

import Firebase, { withFirebase, User, ParkingSpot, Reservation, Car, UserPriorityLevel, Location } from "../../components/Firebase"
import { UserStateCheck } from "../../shared-components/Session"
import Utilities from "../Firebase/Models/utilities"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

import $ from "jquery"

import Swal from "sweetalert2/dist/sweetalert2.js"
import withReactContent from "sweetalert2-react-content"
import { Link, createSearchParams } from "react-router-dom"
import * as ROUTES from "constants/routes"
import { DateCalendar, DateField, LocalizationProvider } from "@mui/x-date-pickers"
import { IntlShape, injectIntl } from "react-intl"
import { Theme, ThemeProvider } from "@mui/material"
import muiTheme from "otherFiles/muiTheme"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"

const MySwal = withReactContent(Swal)

interface IVisitorsBookingBaseState {
    parkingSpots?: ParkingSpot[]
    allowedLocations?: Location[]
    reservations?: Reservation[]
}
interface IVisitorsBookingBaseProps {
    user: User
    firebase: Firebase
    intl: IntlShape
}
class VisitorsBookingBase extends React.Component<IVisitorsBookingBaseProps, IVisitorsBookingBaseState> {
    tomorrow = moment.tz(this.timezone).add(1, "d")
    fromDate = this.tomorrow?.set({ h: 8, m: 0, s: 0, ms: 0 })
    toDate = this.tomorrow?.set({ h: 16, m: 0, s: 0, ms: 0 })

    get timezone() {
        return this.props.user.activeLocation?.timezone ?? "Europe/Prague"
    }

    constructor(props: IVisitorsBookingBaseProps) {
        super(props)

        this.state = {
            parkingSpots: undefined,
            reservations: undefined
        }
    }

    componentDidMount() {
        this.updateData()
        this.fetchAdminPrivileges()
    }

    updateData() {
        this.setState({ reservations: undefined })
        this.fetchParkingSpots()
        this.fetchReservations()
    }

    onFromDateChange = (date: string | moment.Moment | null) => {
        if (date === null) {
            return
        }
        const fromDate = this.onDateChange(date)
        if (fromDate) {
            this.fromDate = fromDate
            this.toDate = fromDate
        }
    }
    onToDateChange = (date: string | moment.Moment | null) => {
        if (date === null) {
            return
        }
        const toDate = this.onDateChange(date)
        if (toDate) {
            this.toDate = toDate
        }
    }
    onDateChange = (date: moment.Moment | string) => {
        const filterDate = moment.isMoment(date) ? date : moment(date)
        if (filterDate.isValid()) {
            return filterDate
        } else {
            return null
        }
    }

    fetchReservations = () => {
        if (this.props.user.uid === undefined || this.props.user.activeLocation === undefined) {
            return Promise.reject(new Error("User has no uid or activeLocation"))
        }
        let startDate = moment().add(-5, "d").toDate()
        let endDate = moment().add(3, "M").toDate()
        return this.props.firebase
            .fetchAllReservationsWithUsers(this.props.user.activeLocation, startDate, endDate, true)
            .then(reservations => {
                this.setState({ reservations: reservations.filter(r => !r.canceled) })
            })
            .catch(e => {
                console.warn("Error fetching reservations: ", e)
            })
    }

    fetchParkingSpots = () => {
        this.props.firebase
            .fetchParkingSpots(this.props.user.activeLocationId, true)
            .then(parkingSpots => {
                parkingSpots.sort((a, b) => a.name.localeCompare(b.name))
                this.setState({ parkingSpots })
            })
            .catch(error => {
                console.warn("Error getting parking spots: ", error)
            })
    }

    onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.name === "location") {
            this.props.user.activeLocationId = event.target.value
            this.props.firebase.setUserActiveLocation(this.props.user, event.target.value)
            this.updateData()
        }
    }

    fetchAdminPrivileges = async () => {
        try {
            const privileges = await this.props.firebase.fetchAdminPrivileges(this.props.user.uid)
            const locations = (await Promise.all(privileges.locationsIds.map(this.props.firebase.fetchLocation))).filter(l => l).map(l => l!)
            this.setState({ allowedLocations: locations })
        } catch (error) {
            this.setState({ allowedLocations: [] })
            console.warn("Error fetching admin privileges: ", error)
        }
    }

    getAvailableSpotsForSelect = () => {
        if (this.props.user.activeLocation === undefined || this.toDate === undefined) {
            return Promise.reject(new Error("User has no activeLocation!"))
        }
        const promises: Promise<ParkingSpot[]>[] = []
        let startTime = moment(this.fromDate)
        const end = this.toDate
        while (startTime <= end) {
            let endTime = moment(startTime).endOf("d")
            if (endTime > end) {
                endTime = end
            }
            promises.push(this.props.firebase.fetchAvailableSpots(this.props.user.activeLocation, startTime.toDate(), endTime.toDate(), true))
            startTime = startTime.add(1, "d").startOf("d")
        }
        return Promise.all(promises).then(spotSets => {
            if (spotSets.length === 0) {
                return Promise.resolve([])
            }
            const availableSpots = spotSets.reduce((acc, x) => intersection(acc, x), spotSets[0])
            return Promise.resolve(availableSpots.reduce((a, spot) => ({ ...a, [spot.id!]: spot.name }), {}))
        })
    }

    handleAddBookingClick = () => {
        let spotId: string = ""
        MySwal.fire({
            title: "Select reservation start date",
            html: (
                <StaticDatePicker
                    date={moment(this.fromDate)}
                    onChange={this.onFromDateChange}
                    timezone={this.props.user.activeLocation?.timezone}
                    locale={this.props.intl.locale}
                    theme={muiTheme}
                />
            ),
            confirmButtonText: "Next &rarr;",
            showCancelButton: true
        })
            .then(result => {
                if (result.isDismissed) {
                    //dismissed dialog
                    return Promise.reject(new Error("closed"))
                }
                return MySwal.fire({
                    title: "Select reservation start time",
                    html: <input className="y-style" id="time-input" defaultValue="08:00" type="time" />,
                    confirmButtonText: "Next &rarr;",
                    showCancelButton: true,
                    preConfirm: () => {
                        return (document!.getElementById("time-input") as HTMLInputElement).value
                    }
                })
            })
            .then(result => {
                if (result.isDismissed) {
                    //dismissed dialog
                    return Promise.reject(new Error("closed"))
                }
                if (this.props.user.activeLocation === undefined || this.fromDate === undefined) {
                    return Promise.reject(new Error("User has no activeLocation!"))
                }
                this.fromDate = Utilities.parseTime(result.value!, this.fromDate.toDate(), this.props.user.activeLocation.timezone)
                return MySwal.fire({
                    title: "Select reservation end date",
                    html: (
                        <StaticDatePicker
                            date={moment(this.toDate)}
                            onChange={this.onToDateChange}
                            timezone={this.props.user.activeLocation.timezone}
                            locale={this.props.intl.locale}
                            theme={muiTheme}
                        />
                    ),
                    confirmButtonText: "Next &rarr;",
                    showCancelButton: true
                })
            })
            .then(result => {
                if (result.isDismissed) {
                    //dismissed dialog
                    return Promise.reject(new Error("closed"))
                }
                return MySwal.fire({
                    title: "Select reservation end time",
                    html: <input className="y-style" id="time-input" defaultValue="16:00" type="time" />,
                    confirmButtonText: "Next &rarr;",
                    showCancelButton: true,
                    preConfirm: () => {
                        return (document!.getElementById("time-input") as HTMLInputElement).value
                    }
                })
            })
            .then(result => {
                if (result.isDismissed) {
                    //dismissed dialog
                    return Promise.reject(new Error("closed"))
                }
                if (this.props.user.activeLocation === undefined || this.toDate === undefined) {
                    return Promise.reject(new Error("User has no activeLocation!"))
                }
                this.toDate = Utilities.parseTime(result.value!, this.toDate.toDate(), this.props.user.activeLocation.timezone)
                MySwal.fire({
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                return this.getAvailableSpotsForSelect()
            })
            .then(result => {
                if (!Object.entries(result).length) {
                    return Promise.reject(new Error("no-spots"))
                } else {
                    return MySwal.fire({
                        title: "Select parking spot",
                        input: "select",
                        inputOptions: result,
                        customClass: { input: "select_arrow y-style" },
                        confirmButtonText: "Next &rarr;",
                        showCancelButton: true
                    })
                }
            })
            .then(result => {
                if (!result.value) {
                    throw new Error("closed")
                }
                spotId = result.value
                return MySwal.fire({
                    title: "Enter reservation details",
                    html:
                        '<input id="name" class="swal2-input y-style" placeholder="Visitor\'s name">' +
                        '<input id="surname" class="swal2-input y-style" placeholder="Visitor\'s surname*">' +
                        '<input id="email" class="swal2-input y-style" placeholder="Visitor\'s email">' +
                        '<input id="license" class="swal2-input y-style" placeholder="License plate">' +
                        '<input id="visitor-company" class="swal2-input y-style" placeholder="Company">' +
                        '<input id="host" class="swal2-input y-style" placeholder="Visit for">' +
                        '<div class="swal2-checkbox mt-2"><input id="email-visitor" type="checkbox" class="swal2-checkbox"><label for="email-visitor">Send email to the visitor</label></div>',
                    preConfirm: () => {
                        const name = $("#name").val() as string | undefined
                        const surname = $("#surname").val() as string | undefined
                        const email = $("#email").val() as string | undefined
                        const license = $("#license").val() as string | undefined
                        const visitorCompany = $("#visitor-company").val() as string | undefined
                        const host = $("#host").val() as string | undefined
                        const sendEmail = $("#email-visitor").prop("checked")
                        if (!surname) {
                            Swal.showValidationMessage(`Please enter visitor's surname`)
                        }
                        return { name, surname, email, license, visitorCompany, host, sendEmail }
                    },
                    didOpen: function () {
                        $("#swal-input1").focus()
                    },
                    confirmButtonText: "Submit",
                    showCancelButton: true
                })
            })
            .then(result => {
                if (!result?.value) {
                    throw new Error("closed")
                }
                if (this.props.user.activeLocation === undefined || this.toDate === undefined) {
                    return Promise.reject(new Error("User has no activeLocation!"))
                }
                const promises: Promise<any>[] = []

                const userId = this.props.firebase.generateUserId()
                let user = new User(userId, result.value?.name ?? "unknown", result.value?.surname ?? "unknown", result.value?.email ?? "unknown")
                user.company = "visitor-" + this.props.user.company
                user.visitorCompany = result.value?.visitorCompany
                user.host = result.value?.host
                promises.push(this.props.firebase.setUser(user))

                let car = new Car({
                    id: this.props.firebase.generateCarId(userId),
                    plateNum: result.value?.license ?? "unknown"
                })
                promises.push(this.props.firebase.createCar(car, userId))

                const spot = this.state.parkingSpots!.find(spot => spot.id === spotId)!
                let startTime = moment(this.fromDate)
                const end = this.toDate
                while (startTime <= end) {
                    let endTime = moment(startTime).endOf("d")
                    if (endTime > end) {
                        endTime = end
                    }
                    const reservation = new Reservation({
                        userId: userId,
                        startTime: startTime.toDate(),
                        endTime: endTime.toDate(),
                        confirmed: true,
                        parkingSpot: spot.name ?? "unknown",
                        parkingSpotId: spotId,
                        priority: UserPriorityLevel.Visitors,
                        carId: car.id
                    })
                    promises.push(this.props.firebase.setReservation(this.props.user.activeLocation, reservation, result.value?.sendEmail))
                    startTime = startTime.add(1, "d").startOf("d")
                }
                MySwal.fire({
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                return Promise.all(promises)
            })
            .then(_ => {
                this.fetchReservations()
            })
            .then(_ => {
                this.updateData()
                return MySwal.fire({
                    title: "Done",
                    text: "Visitor booking saved",
                    confirmButtonText: "OK",
                    icon: "success",
                    timer: 1500,
                    timerProgressBar: true
                })
            })
            .catch(error => {
                if (error.message === "closed") {
                    return
                } else if (error.message === "no-spots") {
                    return MySwal.fire({
                        title: "No spots",
                        text: "There are no available parking places for the selected time period.",
                        icon: "info"
                    })
                }
                MySwal.fire({
                    title: "Error",
                    text: "There was an error while adding booking. Please try again later." + error,
                    icon: "error"
                })
                console.warn("Error adding booking: ", error)
            })
    }

    render() {
        if (this.state.allowedLocations === undefined || this.state.reservations === undefined || this.state.parkingSpots === undefined) {
            return <div className="loader"></div>
        }

        if (this.state.allowedLocations.length === 0) {
            return (
                <div>
                    <br />
                    <h3>You are not authorized to view this page</h3>
                </div>
            )
        }

        const reservationsTable = (
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Reservation date</th>
                            <th>Parking spot</th>
                            <th>Name</th>
                            <th>Surname</th>
                            <th>Email</th>
                            <th>Company</th>
                            <th>Visit for</th>
                            <th>License plate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.reservations
                            ?.filter(r => r.priority === UserPriorityLevel.Visitors)
                            .map((reservation, index) => (
                                <tr key={reservation.id}>
                                    <td>
                                        <span className="light-text">{index + 1}</span>
                                    </td>
                                    <td>
                                        {moment.tz(reservation.date, this.timezone).format("ll")}{" "}
                                        {moment.tz(reservation.startTime, this.timezone).format("H:mm")} -{" "}
                                        {moment.tz(reservation.endTime, this.timezone).format("H:mm")}
                                    </td>
                                    <td>{reservation.parkingSpot}</td>
                                    <td>{reservation.user?.name}</td>
                                    <td>{reservation.user?.surname}</td>
                                    <td>{reservation.user?.email}</td>
                                    <td>{reservation.user?.visitorCompany}</td>
                                    <td>{reservation.user?.host}</td>
                                    <td>{reservation.user?.cars?.find(car => car.id === reservation?.carId)?.plateNum}</td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        )

        return (
            <div>
                {this.state.allowedLocations.length > 1 ? (
                    <div>
                        <span className="my-2 mr-2">Location:</span>
                        <select
                            className="mb-3 select_arrow y-style"
                            name="location"
                            id="location"
                            value={this.props.user.activeLocationId}
                            onChange={this.onSelectChange}
                        >
                            {this.state.allowedLocations
                                ?.sort((a, b) => a.title.localeCompare(b.title))
                                .map(location => {
                                    return (
                                        <option key={location.code} value={location.code}>
                                            {location.title}
                                        </option>
                                    )
                                })}
                        </select>
                    </div>
                ) : (
                    <div></div>
                )}
                <div>
                    <button onClick={this.handleAddBookingClick} className="my-2 btn btn-primary">
                        <FontAwesomeIcon icon={faPlus} /> Add booking
                    </button>
                </div>
                {reservationsTable}
                <Link
                    to={{
                        pathname: ROUTES.VISITORS_BOOKING_PREVIEW,
                        search: createSearchParams(
                            this.props.user.activeLocation !== undefined ? { location: this.props.user.activeLocation!.code } : {}
                        ).toString()
                    }}
                >
                    Preview
                </Link>
            </div>
        )
    }
}

const VisitorsBooking = (props: any) => (
    <div className="parkfair-container">
        <h3>Visitors booking</h3>
        <hr className="d-none d-md-block" />
        <UserStateCheck>
            {userInfo => (
                <Fade>
                    <VisitorsBookingBase user={userInfo.user} {...props} />
                </Fade>
            )}
        </UserStateCheck>
    </div>
)

export default injectIntl(withFirebase(VisitorsBooking))

function intersection(a: ParkingSpot[], b: ParkingSpot[]) {
    return a.filter(spotA => b.find(spotB => spotA.id === spotB.id) !== undefined)
}

interface IStaticDatePickerProps {
    date: moment.Moment
    onChange: (date: string | moment.Moment | null) => void
    timezone?: string
    locale?: string
    theme?: Theme
}

export const StaticDatePicker: FC<IStaticDatePickerProps> = ({ date, onChange, timezone, locale, theme }): ReactElement => {
    const onChangeSetValue = <T,>(newValue: moment.Moment | null, _: T) => {
        if (newValue === null) {
            return
        }
        setValue(newValue)
    }

    const [value, setValue] = useState(date)
    useEffect(() => {
        onChange(value)
    }, [value, onChange])

    return (
        <>
            <WrapIfDefined value={theme} wrapper={(theme, children) => <ThemeProvider theme={theme}>{children}</ThemeProvider>}>
                <WrapIfDefined
                    value={locale}
                    wrapper={(locale, children) => (
                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
                            {children}
                        </LocalizationProvider>
                    )}
                >
                    <DateField value={value} onChange={onChangeSetValue} timezone={timezone} required inputProps={{ className: "calendar" }} />
                    <DateCalendar value={value} onChange={onChangeSetValue} timezone={timezone} views={["day"]} />
                </WrapIfDefined>
            </WrapIfDefined>
        </>
    )
}

interface IWrapIfDefinedProps<T> {
    value: T | undefined
    wrapper: (value: T, children: ReactNode) => ReactNode
    children: ReactNode
}
const WrapIfDefined = <T,>({ value, wrapper, children }: IWrapIfDefinedProps<T>): ReactNode =>
    value !== undefined ? wrapper(value, children) : children
