import car from "assets/icons/car.svg"
import saplogo from "assets/sap-logo.svg"

export default function OccupancyCard({ title, occupancyForDay }: { title: string; occupancyForDay: number }) {
    return (
        <div className="pt-5 m-3">
            <div
                className="sap-container"
                style={{
                    backgroundColor: occupancyForDay === 0 ? "#FF637D" : occupancyForDay >= 1 && occupancyForDay <= 4 ? "#FACD90" : "#D8FCD4"
                }}
            >
                <img src={car} alt="car icon" className="sap-icon" />
                <div className="d-flex justify-content-center align-items-center">
                    <h1 className="fw-bold sap-location">{title}</h1>
                </div>
                <h1
                    className="display-1 fw-bold"
                    style={{
                        color: "#33058D"
                    }}
                >
                    {occupancyForDay}
                </h1>
                <img src={saplogo} alt="location icon" className="sap-logo" width={47} height={23} />

                <h4 className="mb-3 text-center">Parking Spots Available</h4>
            </div>
        </div>
    )
}
