import React, { BaseSyntheticEvent } from "react"
import { Link } from "react-router-dom"
import { FormattedMessage as M } from "react-intl"

import * as ROUTES from "constants/routes"
import Firebase, { User, withFirebase, Language } from "components/Firebase"
import { AuthUserContext, UserState, UserAuthInfo, isInWebView } from "shared-components/Session"
import NavDropdown from "react-bootstrap/NavDropdown"

import "styles/navigation.scss"

import logo from "assets/logo.svg"
//import user_icon from "assets/icons/user.svg"

import { slide as Menu } from "react-burger-menu"

interface IUserInfoProps {
    firebase: Firebase
    userState: UserState
    handleOnClick(): void
}

class UserInfo extends React.Component<IUserInfoProps> {
    handleSignOutClick = () => {
        this.props.handleOnClick()
        this.props.firebase.signOut()
    }

    render() {
        switch (this.props.userState) {
            case UserState.Unknown:
                return (
                    <Link className="menu-item" to="">
                        Loading ...
                    </Link>
                )
            case UserState.Auth:
            case UserState.Deleted:
                /*
                const dropDownTitle = (
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"className="small-img">
                            <path className="cls-1" d="M15,15a6.33,6.33,0,1,1,6.33-6.33A6.34,6.34,0,0,1,15,15Zm10.61,4.39a15,15,0,0,0-5.7-3.57,8.67,8.67,0,1,0-9.82,0A15,15,0,0,0,0,30H2.34a12.66,12.66,0,1,1,25.32,0H30A14.93,14.93,0,0,0,25.61,19.39Z"/>
                        </svg>
                        {this.props.authUser.name}
                    </div>
                )*/
                return (
                    <React.Fragment>
                        <Link className="menu-item" to={ROUTES.PROFILE} onClick={this.props.handleOnClick}>
                            <M id="nav.my_profile" />
                        </Link>
                        <Link className="menu-item" to={ROUTES.RESERVE} onClick={this.handleSignOutClick}>
                            <M id="user.sign_out" />
                        </Link>
                    </React.Fragment>
                )
            default:
                return (
                    <React.Fragment>
                        <Link className="menu-item" to={ROUTES.SIGN_UP} onClick={this.props.handleOnClick}>
                            <M id="nav.sign_up" />
                        </Link>
                        <Link className="menu-item" to={ROUTES.SIGN_IN} onClick={this.props.handleOnClick}>
                            <M id="user.sign_in" />
                        </Link>
                    </React.Fragment>
                )
        }
    }
}

interface IAdminSectionState {
    isAdmin: boolean
    isFleetAdmin: boolean
}
interface IAdminSectionProps {
    firebase: Firebase
    user: User
    handleOnClick(): void
}
class AdminSection extends React.Component<IAdminSectionProps, IAdminSectionState> {
    constructor(props: IAdminSectionProps) {
        super(props)

        this.state = {
            isAdmin: false,
            isFleetAdmin: false
        }
    }

    componentDidMount() {
        this.fetchAdminPrivileges()
    }

    fetchAdminPrivileges = async () => {
        try {
            const privileges = await this.props.firebase.fetchAdminPrivileges(this.props.user.uid)
            if (privileges.companies.length > 0) {
                this.setState({ isAdmin: true })
            }
            if (privileges.fleetIds.length > 0) {
                this.setState({ isFleetAdmin: true })
            }
        } catch (error) {
            console.warn("Error fetching admin privileges: ", error)
        }
    }

    render() {
        if (!this.state.isAdmin) {
            return <div></div>
        } else {
            return (
                <React.Fragment>
                    <hr className="my-1" />
                    <Link className="menu-item" to={ROUTES.ADMIN} onClick={this.props.handleOnClick}>
                        Admin
                    </Link>
                    <Link className="menu-item" to={ROUTES.VISITORS_BOOKING} onClick={this.props.handleOnClick}>
                        <M id="nav.visitors_booking" />
                    </Link>
                    <Link className="menu-item" to={ROUTES.ANALYTICS} onClick={this.props.handleOnClick}>
                        <M id="nav.analytics" />
                    </Link>
                    <Link className="menu-item" to={ROUTES.USER_MANAGEMENT} onClick={this.props.handleOnClick}>
                        <M id="nav.user_management" />
                    </Link>
                    <Link className="menu-item" to={ROUTES.PARKING_MANAGEMENT} onClick={this.props.handleOnClick}>
                        <M id="nav.parking_management" />
                    </Link>
                    <Link className="menu-item" to={ROUTES.ABUSE_REPORT} onClick={this.props.handleOnClick}>
                        <M id="nav.abuses_report" />
                    </Link>
                    {this.state.isFleetAdmin ? (
                        <>
                            <hr className="my-1" />
                            <Link className="menu-item" to={ROUTES.FLEET_ADMIN} onClick={this.props.handleOnClick}>
                                Fleet admin
                            </Link>
                            <Link className="menu-item" to={ROUTES.FLEET_CARS_MANAGEMENT} onClick={this.props.handleOnClick}>
                                Fleet cars management
                            </Link>
                        </>
                    ) : (
                        <></>
                    )}
                </React.Fragment>
            )
        }
    }
}

interface INavigationPanelState {
    isOpen: boolean
}
interface INavigationPanelProps {
    setLanguage: (languageId: string) => void
    selectedLanguageId: string
    languages: Language[]
    firebase: Firebase
    userInfo: UserAuthInfo
}

class NavigationPanel extends React.Component<INavigationPanelProps, INavigationPanelState> {
    constructor(props: INavigationPanelProps) {
        super(props)

        this.state = {
            isOpen: this.isBigScreen()
        }
    }

    updateOpenState = () => {
        this.setState({ isOpen: this.isBigScreen() })
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateOpenState)
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateOpenState)
    }

    isBigScreen = () => {
        return window.innerWidth > 768
    }

    handleOnClick = () => {
        this.handleOnClose()
    }

    handleOnOpen = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    handleOnClose = () => {
        if (!this.isBigScreen()) {
            this.setState({ isOpen: false })
        }
    }

    handleLanguageSelect = (event: BaseSyntheticEvent) => {
        const languageId = event?.target?.id

        if (this.props.languages.find(l => l.id === languageId) !== undefined) {
            this.props.setLanguage(languageId)
        }
    }

    render() {
        return (
            <Menu
                noOverlay={this.isBigScreen()}
                disableOverlayClick={this.isBigScreen()}
                disableCloseOnEsc={this.isBigScreen()}
                isOpen={this.state.isOpen}
                customCrossIcon={false}
                width={260}
                disableAutoFocus
                onOpen={this.handleOnOpen}
                onClose={this.handleOnClose}
                burgerBarClassName={this.state.isOpen ? "bm-burger-bars_open" : "bm-burger-bars_closed"}
                outerContainerId="root"
            >
                <div className="menu-section">
                    <div className="menu-spacer d-md-none"></div>
                    <Link to={ROUTES.RESERVE} className="m-3 logo-block d-none d-md-block" onClick={this.handleOnClick}>
                        <img className="logo" src={logo} alt="logo" />
                    </Link>
                    {/*<Link className="menu-item" to={ROUTES.PARKING} onClick={this.handleOnClick}>My parking</Link>*/}
                    <Link className="menu-item" to={ROUTES.RESERVE} onClick={this.handleOnClick}>
                        <M id="nav.my_reservations" />
                    </Link>
                    {(this.props.userInfo.user?.fleetIds?.length ?? 0) > 0 ? (
                        <Link className="menu-item" to={ROUTES.FLEET_RESERVATION} onClick={this.handleOnClick}>
                            <M id="nav.fleet_reservation" />
                        </Link>
                    ) : (
                        <></>
                    )}
                    {isInWebView() || !this.props.userInfo.user ? (
                        <div></div>
                    ) : (
                        <AdminSection firebase={this.props.firebase} user={this.props.userInfo.user} handleOnClick={this.handleOnClick} />
                    )}
                </div>
                <div className="menu-section">
                    <NavDropdown
                        id="nav-dropdown"
                        className="menu-item"
                        title={this.props.languages.find(l => l.id === this.props.selectedLanguageId)?.title ?? this.props.languages[0].title}
                    >
                        {this.props.languages.map(language => {
                            return (
                                <NavDropdown.Item key={language.id} id={language.id} onClick={this.handleLanguageSelect}>
                                    {language.title}
                                </NavDropdown.Item>
                            )
                        })}
                    </NavDropdown>
                    <hr className="my-1" />
                    <UserInfo firebase={this.props.firebase} userState={this.props.userInfo.state} handleOnClick={this.handleOnClick} />
                    <hr className="my-1" />
                    <Link className="menu-item" to={ROUTES.CONTACTS} onClick={this.handleOnClick}>
                        <M id="footer.contacts" />
                    </Link>
                    {this.props.userInfo.user?.company === "kiwi" ? (
                        <Link className="menu-item" to={ROUTES.KIWI_RULES} onClick={this.handleOnClick}>
                            Parking rules
                        </Link>
                    ) : (
                        ""
                    )}
                    {isInWebView() ? (
                        <div></div>
                    ) : (
                        <React.Fragment>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="menu-item"
                                href="https://apps.apple.com/cz/app/parkfair/id1537973020"
                                onClick={this.handleOnClick}
                            >
                                Get iOS app
                            </a>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="menu-item"
                                href="https://play.google.com/store/apps/details?id=cz.parkfair.app"
                                onClick={this.handleOnClick}
                            >
                                Get Android app
                            </a>
                        </React.Fragment>
                    )}
                    <hr className="my-1" />
                    <a target="_blank" rel="noopener noreferrer" className="menu-item" href="https://www.yedem.io/pp" onClick={this.handleOnClick}>
                        <M id="footer.pp" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" className="menu-item" href="https://www.yedem.io/terms" onClick={this.handleOnClick}>
                        <M id="footer.terms" />
                    </a>
                </div>
            </Menu>
        )
    }
}

const NavigationPanelBase = (props: any) => (
    <AuthUserContext.Consumer>{userInfo => <NavigationPanel {...props} userInfo={userInfo} />}</AuthUserContext.Consumer>
)

export default withFirebase(NavigationPanelBase)
