import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import * as ROUTES from "constants/routes"

import SAP from "components/Statics/sap"
import MainLayout from "./mainLayout"

function App() {
    return (
        <Router>
            <Routes>
                <Route path="*" element={<MainLayout />} />
                <Route path={ROUTES.SAP} element={<SAP />} />
            </Routes>
        </Router>
    )
}

export default App
