export const RESERVE = "/"
export const HOME = RESERVE
//export const PARKING = '/';
export const CANCEL_RESERVATION = "/cancel-reservation/:reservationId"
export const AUTHORIZE_SLACK = "/authorize-slack"
export const RESERVATIONS_OVERVIEW = "/reservations-overview"
export const FLEET_RESERVATION = "/fleet-reservation"

export const PROFILE = "/profile"
export const EDIT_PROFILE = "/edit-profile"
export const SIGN_UP = "/signup"
export const SIGN_IN = "/signin"
export const CARS = "/cars"
export const SIGN_IN_FINISH = RESERVE

export const ONBOARDING = "/onboarding"

export const ABOUT = "/about"
export const CONTACTS = "/contacts"

export const KIWI_RULES = "/rules-kiwi"

export const ADMIN = "/admin"
export const VISITORS_BOOKING = "/visitors-booking"
export const VISITORS_BOOKING_PREVIEW = "/visitors-booking-preview"
export const ANALYTICS = "/analytics"
export const ABUSE_REPORT = "/abuses-report"
export const USER_MANAGEMENT = "/user-management"
export const PARKING_MANAGEMENT = "/parking-management"
export const FLEET_CARS_MANAGEMENT = "/fleet-cars-management"
export const FLEET_ADMIN = "/fleet-admin"

export const SAP = "/sap"
